<template>
    <div>
        <div class="row mt-3 mb-3" v-if="filterPoints.length>0">
            <div class="col-sm-12">
                <h4 class="font-weight-bold">Mes points pour le cycle ou le mois en cours ({{ filterPoints.length }})</h4>
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col-sm-12">
              <div class="table-responsive">
                <table class="table table-hover" v-if="filterPoints.length">
                  <thead>
                  <tr class="table-head">
                    <th scope="col">Code de la commande</th>
                    <th scope="col">Date de la commande</th>
                    <th scope="col">Date de traitement</th>
                    <th scope="col">Point de  la commande</th>
                  </tr>
                  </thead>
                  <tr v-for="(item,index) in filterPoints" :key="index">
                    <td>{{ item.code }}</td>
                    <td>{{ new Date(item.created_at) | date }}</td>
                    <td>{{ new Date(item.updated_at) | date }}</td>
                    <td>{{ item.total_point_invoiced }}</td>
                  </tr>
                  <tfoot >
                  <tr>
                    <td colspan="3">
                      <b>POINT TOTAL: </b>
                    </td>
                    <td style="text-align: left;"><b>{{totalPoints| numeral("0,0.00")}}</b></td>
                  </tr>
                  </tfoot>
                </table>
                <h4 v-else>Vous n'avez pas encore marqué de point</h4>
              </div>

            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'

  export default {
    name: "points",

    props:{
      points :{
        required: true,
        default: () => []
      }
    },

    computed: {
      filterPoints(){
        // return _.filter(this.points, (o) => o.total_point_invoiced > 0 && o.shipments.length>0)
        return _.filter(this.points, (o) => +o.total_point_invoiced > 0)
      },
      totalPoints(){
        return this.filterPoints.reduce((total, point)=>{
          return total+parseFloat(point.total_point_invoiced)

        }, 0.0)
      }
    }
  };
</script>

<style scoped>

</style>
