<template>
    <div>
<!--        <div class="row mt-3 mb-3" v-if="totalCustomersPayback>0">
            <div class="col-sm-12">
                <h4 class="font-weight-bold">Mes {{ title }}s pour le cycle ou le mois en cours ({{ totalCustomersPayback }})</h4>
            </div>
        </div>-->
        <div class="row mt-3 mb-3">
            <div class="col-sm-12">
              <div class="table-responsive" v-if="totalCustomersPayback>0">
                <table class="table table-hover" >
                  <thead>
                  <tr class="table-head">
                    <th scope="col">{{ title }}s</th>
                    <th scope="col">Statut</th>
                    <th scope="col" v-if="title === 'Prime'">Type de prime</th>
                    <th scope="col">Date</th>
                  </tr>
                  </thead>
                  <template v-for="(bonus) in customersPaybacks">
                    <tr v-for="(item,index) in bonus.transactions" :key="index">
                      <td>
                        {{ item.amount | numeral("0,0.00") }} HTG
                      </td>
                      <td>
                        {{ item.level.name }}
                      </td>
                      <td v-if="title === 'Prime'">
                        {{ bonus.reward.name }}
                      </td>
                      <td>
                        {{ new Date(item.created_at) | date  }}
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="4"><b>TOTAL: {{totalAmountCustomersPayback | numeral("0,0.00")}} HTG</b></td>
                  </tr>
                </table>
              </div>
              <h4 v-else>Vous n'avez pas encore reçu de {{ title }}s</h4>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "customersPayback",

    props:{
      title :{
        required: true,
      },

      customersPaybacks :{
        required: true,
      }
    },
    computed: {
      test: function (){
        console.log('customersPaybacks', this.customersPaybacks)
        return 1;
      },
      totalCustomersPayback(){
        let total = 0;
        this.customersPaybacks.map(bonus=>{
          // eslint-disable-next-line no-unused-vars
          bonus.transactions.map(item=>{
            total += 1;
          })
        })
        return total
      },

      totalAmountCustomersPayback(){
        let total = 0.0;
        this.customersPaybacks.map(bonus=>{
          bonus.transactions.map(item=>{
            total += parseFloat(item.amount);
          })
        })
        console.log('total', total)
        return total
      }


    }
  };
</script>

<style scoped>

</style>
