<template>
    <div>
        <div class="row mt-3 mb-3" v-if="nouveauInscrits.length>0">
            <div class="col-sm-12">
                <h4 class="font-weight-bold">Nouveaux inscrits pour le cycle ou le mois en cours ({{nouveauInscrits.length}})</h4>
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col-sm-12" style="display:inline;overflow: scroll">
                <table class="table cart-table table-responsive-xs" v-if="nouveauInscrits.length>0">
                    <tr class="thead text-uppercase">
                        <th scope="col">Nom</th>
                        <th scope="col">Prenom</th>
                        <th scope="col">CIM</th>
                        <th scope="col">Statut</th>
                        <th scope="col">Situation</th>
                        <th scope="col">Reference</th>
                        <th scope="col">Tel</th>
                        <th scope="col">Email</th>
                    </tr>
                    <tr v-for="(item,index) in nouveauInscrits" :key="index">
                        <td>
                            {{ item.fname }}
                        </td>
                        <td>
                            {{ item.lname }}
                        </td>
                        <td>
                            {{ item.code }}
                        </td>
                        <td>
                            {{ item.level.name  }}
                        </td>
                        <td>
                            <span class="badge" style="color: white" :style="{backgroundColor: item.situationColor}">{{ item.situationLabel }}</span>
                        </td>
                        <td>
                            {{ item.reference.full_name}} <br/> {{ item.reference.code}}
                        </td>
                        <td>
                            {{ item.tel}}
                        </td>
                        <td>
                            {{ item.user.email }}
                        </td>
                    </tr>
                </table>
              <h4 v-else>Il n'y a pas encore de nouveaux inscrits dans votre réseau</h4>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "newSubscription",

    data(){
      return {

      }
    },

    computed: {
      ...mapGetters({
        loginCustomer : 'auth/user',
        nouveauInscrits : 'customer/newCustomers'
      })
    },

    methods: {
      ...mapActions({
        getNewCustomer : 'customer/fetchNewCustomer'
      })
    },

    mounted() {
      this.getNewCustomer(this.loginCustomer.id)
    }
  };
</script>

<style scoped>

</style>
