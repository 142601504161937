<template>
  <div>
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4 class="font-weight-bold">
          Mes commandes pour le cycle ou le mois en cours ({{
            commandes.length
          }})
        </h4>
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div
        class="col-sm-12"
        style="margin-bottom: 20px;border: 1px solid #ddd;"
        v-for="item in commandes"
        :key="item.id"
      >
        <div
          class="row mt-3 mb-3"
          style="
            background: #f79420;
            min-height: 30px;
            margin-top: -3px !important;
            padding-top: 10px;
            padding-bottom: 5px;
          "
        >
          <div class="col-sm-4" style="color: white">
            <b>#Commande:</b> {{ item.code }}
          </div>
          <div class="col-sm-4" style="color: white">
            <b>Statut:</b>&nbsp;
            <span
              class="badge p-1"
              style="color: #000"
              :style="{ 'background-color': item.statusColor }"
              >{{ item.statusLabel }}</span
            >
          </div>
          <div class="col-sm-4" style="color: white">
            <b>Date:</b> {{ new Date(item.created_at) | date }}
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover cart-table">
            <tr class="thead text-uppercase">
              <th>Produit</th>
              <th>Qte</th>
              <th scope="col">Prix</th>
              <th scope="col">Cash Back</th>
              <th scope="col">Point</th>
            </tr>
            <tr v-for="orderItem in item.orderItems" :key="orderItem.id">
              <td class="border-0" scope="col">
                <img
                    :src="orderItem.product.images[0].original_url"
                    width="40"
                    alt=""
                />
                {{ orderItem.product.name }} <br />
<!--                <span v-html="orderItem.product.description.slice(0, 200)"></span>-->
              </td>
              <td class="border-0">
                {{ orderItem.qty_ordered | numeral("0,0") }} X
              </td>
              <td scope="col" class="border-0">
                {{ orderItem.price | numeral("0,0.00") }} ({{
                  orderItem.product.currency.symbol
                }})
              </td>
              <td scope="col" class="border-0" v-if="item.discount_type=='PERCENT'">
                {{ (orderItem.price * (orderItem.discount/100)) | numeral("0,0.00") }} ({{
                  orderItem.product.currency.symbol
                }})
              </td>
              <td scope="col" class="border-0" v-else>
                {{ orderItem.discount | numeral("0,0.00") }} ({{
                  orderItem.product.currency.symbol
                }})
              </td>
              <td scope="col" class="border-0">
                {{ orderItem.point | numeral("0,0.00") }} (Pts)
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                Sous-total: {{ item.subtotal | numeral("0,0.00") }}
                {{ item.currency.symbol }}<br /><br />
                Frais: {{ totalFees(item) | numeral("0,0.00") }}
                {{ item.currency.symbol }}<br /><br />
                Shipping: {{ item.shipping_amount | numeral("0,0.00") }}
                {{ item.currency.symbol }}<br /><br />
                <b>Total: {{ item.total | numeral("0,0.00") }}
                  {{ item.currency.symbol }}</b>
              </td>
              <td>
                Total CB: {{ item.discount | numeral("0,0.00") }}
                {{ item.currency.symbol }}
              </td>
              <td>
                Total pts: {{ item.total_point | numeral("0,0.00") }}
              </td>
            </tr>
          </table>
        </div>

        <!--<div class="row mt-3 mb-3">-->
          <!--<div class="col-sm-12" style="text-align: right">-->
            <!--<button-->
              <!--type="submit"-->
              <!--@click="pay(item.id)"-->
              <!--:disabled="invalid"-->
              <!--class="btn-solid btn"-->
            <!--&gt;-->
              <!--<i class="fa fa-credit-card"></i> Payer-->
            <!--</button>-->
          <!--</div>-->
        <!--</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "cancelableOrders",

  props: {
    commandes: {
      required: true,
      default: () => [],
    },
  },

  methods: {
    totalFees(item) {
      console.log("item", item);
      console.log(item.fee_amount);
      console.log(item.global_fee);
      return _.add(parseFloat(item.fee_amount), parseFloat(item.global_fee));
    },
    async pay(payload) {
      const payment = await this.$store.dispatch("order/payment", payload);
      console.log(payment);
      if (payment.success) {
        window.location.href = payment.data.link;
      } else {
        this.$swal("Oops", payment.message, "error");
      }
    },
  },

  computed: {},
};
</script>

<style scoped></style>
