<template>
    <section class="cart-section section-b-space">
        <div class="container">
            <div class="row">
                <b-card no-body v-bind:class="'dashboardtab'">
                    <b-tabs pills card vertical>
                        <b-tab title="Nouveaux inscrits" active>
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                        <new-subscription />
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Commandes">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                       <cancelable-orders :commandes="commandes"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Achats">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                        <orders :achats="achats"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Achats d'Equipe">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                        <achat-equipe :commandes="achatEquipes"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Points">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                        <points :points="orders"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Bonus">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                        <customersPayback title="bonu" :customersPaybacks="bonus"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Commissions">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                        <customersPayback title="commission" :customersPaybacks="commissions"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="CashBack">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
<!--                                        <cash-back :cashbacks="allOrders"/>-->
                                      <customersPayback title="cashback" :customersPaybacks="cashback"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
<!--                      <b-tab title="CashBack">
                        <b-card-text>
                          <div class="dashboard-right">
                            <div class="dashboard">
                              <cash-back :cashbacks="allOrders"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>-->
                        <b-tab title="Primes leadership">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                        <customersPayback title="prime" :customersPaybacks="primes"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Salaire">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                        <customersPayback title="salaire" :customersPaybacks="salaries"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Revenu  total">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                        <totals :totalIncome="totalIncome"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Archives">
                            <b-card-text>
                                <div class="dashboard-right">
                                    <div class="dashboard">
                                      <archive :wallet="walletArchived" :allOrders="allOrders"/>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
    </section>
</template>

<script>
    import _ from 'lodash';
    import NewSubscription from './reportComponents/newSubscription'
    import Orders from './reportComponents/orders'
    import CancelableOrders from "./reportComponents/cancelableOrders";
    import { mapActions, mapGetters } from "vuex";
    import Points from "./reportComponents/points";
    //import CashBack from "./reportComponents/cashBack";
    import CustomersPayback from "./reportComponents/customerPayback";
    import Totals from "./reportComponents/totals";
    import Archive from "./reportComponents/archive";
    import AchatEquipe from "./reportComponents/achatEquipe";

  export default {
    name: "report",
    components: { AchatEquipe, Archive, Totals, CustomersPayback, /*CashBack,*/ Points, CancelableOrders, NewSubscription, Orders },

    data(){
      return {

      }
    },

    methods: {
      ...mapActions({
        getOrders : 'order/fetchOrders',
        getWallets : 'wallet/fetchWallets',
        getWalletArchived : 'wallet/fetchWalletsArchived',
        getWalletAll : 'wallet/fetchWalletsAll',
        getTotalIncome : 'wallet/fetchTotalIncome',
      })
    },

    computed: {
      ...mapGetters({
        loginCustomer : 'auth/user',
        orders : 'order/orders',
        allOrders : 'order/allOrders',
        wallet: 'wallet/wallet',
        walletArchived: 'wallet/walletArchived',
        walletAll: 'wallet/walletAll',
        totalIncome: 'wallet/totalIncome',
        achatEquipes : 'order/teamOrders'
      }),

      commandes(){
        return _.filter(this.orders, (o) =>  _.includes(["pending", "canceled"], o.status))
      },

      achats(){
        return _.filter(this.orders, (o) => _.includes(["completed", "processing"], o.status))
      },

      cashback(){
        return _.filter(this.wallet.walletCycles, (o) => o.reward.code === 'cashback')
      },

      commissions(){
        return _.filter(this.wallet.walletCycles, (o) => o.reward.code === 'commission')
      },

      primes(){
        return _.filter(this.wallet.walletCycles,  (o) => (o.reward.code === 'prime_direct' || o.reward.code === 'prime_indirect'))
      },

      bonus(){
        return _.filter(this.wallet.walletCycles, (o) => (o.reward.code === 'bonus_one' || o.reward.code === 'bonus_two'))
      },

      salaries(){
        return _.filter(this.wallet.walletCycles, (o) => o.reward.code === 'salary')
      },
    },

    mounted() {
      this.getOrders(this.loginCustomer.id)
      this.getWallets(this.loginCustomer.id)
      this.getWalletArchived(this.loginCustomer.id)
      //this.getWalletAll(this.loginCustomer.id)
      this.getTotalIncome(this.loginCustomer.id)
      console.log('walletArchived',this.walletArchived)
    }
  };
</script>

<style scoped>

</style>
