<template>
    <div>
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <h4 class="font-weight-bold">Achats de mon équipe pour le cycle ou le mois en cours ({{ commandes.length }})</h4>
        </div>
      </div>
        <div class="row mt-3 mb-3">
            <div class="col-sm-12">
                <div class="table-responsive">
                    <table class="table table-hover" v-if="commandes.length > 0">
                      <thead>
                        <tr class="thead">
                            <th>CIM</th>
                            <th>Code Commande</th>
                            <th>Date de la commande</th>
                            <th>Date de traitement</th>
                            <th>Point</th>
                        </tr>
                      </thead>
                        <tr v-for="item in commandes" :key="item.id">
                              <td>{{ item.customer_code }}</td>
                              <td>{{ item.code }}</td>
                              <td>{{ new Date(item.created_at) | date }}</td>
                              <td>{{ new Date(item.updated_at) | date }}</td>
                              <td>{{ item.total_point | numeral('0,0.00') }}</td>
                        </tr>
                      <tfoot >
                        <tr>
                          <td colspan="4" style="text-align: right;"><b>POINT TOTAL: </b></td>
                          <td style="text-align: left;"><b>{{totalPoints| numeral("0,0.00")}}</b></td>
                        </tr>
                      </tfoot>
                    </table>
                    <h4 v-else>Votre équipe n'a fait aucun achat</h4>
<!--                    <table class="table table-hover cart-table" v-for="item in commandes" :key="item.id">-->
<!--                        <tr class="border-0">
                            <td colspan="6" class="border-0">
                                <div class="row">
                                    <div class="col-12">
                                            <table style="width:100%">
                                                <tr v-for="orderItem in item.orderItems" :key="orderItem.id">
                                                    <td class="border-0" scope="col">
                                                        <img :src="orderItem.product.images[0].original_url" width="40" alt=""/>
                                                    </td>
                                                    <td class="border-0">
                                                        {{ orderItem.product.name}} <br/>
                                                        <span v-html="orderItem.product.description.slice(0, 200)"></span>
                                                    </td>
                                                    <td class="border-0">{{ orderItem.qty_ordered | numeral('0,0') }} X</td>
                                                    <td scope="col" class="border-0">
                                                        {{ orderItem.product.price | numeral('0,0.00')}} ({{ orderItem.product.currency.symbol }})
                                                    </td>
                                                    <td scope="col" class="border-0">{{ orderItem.product.point | numeral('0,0.00') }} (Pts)</td>
                                                    &lt;!&ndash;<th scope="col">Action</th>&ndash;&gt;
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                            </td>
                        </tr>-->
<!--                    </table>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "achatEquipe",


    props: {
      commandes: {
        required: true,
        default: () => []
      }
    },
    computed: {
      totalPoints(){
        return this.commandes.reduce((total, point)=>{
          console.log('total', total)
          return total+parseFloat(point.total_point_invoiced)

        }, 0.0)
      }
    }
  };
</script>

<style scoped>

</style>
