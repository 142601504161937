<template>
  <div>
    <div class="row mt-3 mb-3">
      <div class="col-sm-12">
        <h4 class="font-weight-bold">
          Mes achats pour le cycle ou le mois en cours ({{ achats.length }})
        </h4>
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div
        class="col-sm-12"
        style="margin-bottom: 20px; border: 1px solid #ddd"
        v-for="item in achats"
        :key="item.id"
      >
        <div
          class="row mt-3 mb-3"
          style="
            background: #f79420;
            min-height: 30px;
            margin-top: -3px !important;
            padding-top: 10px;
            padding-bottom: 5px;
          "
        >
          <div class="col-sm-4" style="color: white">
            <b>#Commande:</b> {{ item.code }}
          </div>
          <div class="col-sm-4" style="color: white">
            <b>Statut: {{ item.statusLabel }}</b>&nbsp;
<!--            <span
              class="badge p-1"
              style="color: #000"
              :style="{ 'background-color': item.statusColor }"
              >{{ item.statusLabel }}</span
            >-->
          </div>
          <div class="col-sm-4" style="color: white">
            <b>Date:</b> {{ new Date(item.created_at) | date }}
          </div>
        </div>
        <div class="row mt-3 mb-3"> <div class="col-sm-4"><span v-if="archived"><i class="ti-save"></i>&emsp;|&emsp;<i class="ti-trash" style="color: red"></i></span><br/></div></div>
        <div class="table-responsive">
          <table class="table table-hover table-wrapper table-responsive-xs">
            <tr class="thead text-uppercase">
              <th>Produit</th>
              <th>Qte</th>
              <th scope="col">Prix</th>
              <th scope="col">Cash Back</th>
              <th scope="col">Point</th>
            </tr>
            <tr v-for="orderItem in item.orderItems" :key="orderItem.id">
              <td class="border-0" scope="col">
                <img
                    :src="orderItem.product.images[0].original_url"
                    width="40"
                    alt=""
                />
                {{ orderItem.product.name }} <br />
<!--                <span v-html="orderItem.product.description.slice(0, 200)"></span>-->
              </td>
              <td class="border-0">
                {{ orderItem.qty_ordered | numeral("0,0") }} X
              </td>
              <td scope="col" class="border-0">
                {{ orderItem.price | numeral("0,0.00") }} ({{
                  orderItem.product.currency.symbol
                }})
              </td>
              <td scope="col" class="border-0">
                {{ orderItem.total_discount | numeral("0,0.00") }} ({{
                  orderItem.product.currency.symbol
                }})
              </td>
              <td scope="col" class="border-0">
                {{ orderItem.point | numeral("0,0.00") }} (Pts)
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                Sous-total: {{ item.subtotal | numeral("0,0.00") }}
                {{ item.currency.symbol }}<br /><br />
                Frais: {{ totalFees(item) | numeral("0,0.00") }}
                {{ item.currency.symbol }}<br /><br />
                Shipping: {{ item.shipping_amount | numeral("0,0.00") }}
                {{ item.currency.symbol }}<br /><br />
                <b>Total: {{ item.total | numeral("0,0.00") }}
                {{ item.currency.symbol }}</b>
              </td>
              <td>
                Total CB: {{ item.discount | numeral("0,0.00") }}
                {{ item.currency.symbol }}
              </td>
              <td>
                Total pts: {{ item.total_point | numeral("0,0.00") }}
              </td>
            </tr>
          </table>
        </div>

      </div>
      <!--                <div class="table-responsive">
                    <table class="table table-hover cart-table">
                        <tr class="thead text-uppercase">
                            <th >Commande</th>
                            <th>Qte</th>
                            <th>Sous-total (HTG)</th>
                            <th scope="col">Point</th>
                            <th scope="col">Date</th>
                            <th scope="col">Statut</th>
                            &lt;!&ndash;<th scope="col">Action</th>&ndash;&gt;
                        </tr>
                    </table>
                    <table class="table table-hover cart-table" v-for="item in achats" :key="item.id">
                        <tr>
                            <td>
                                {{ item.code }}
                            </td>
                            <td>
                                {{ item.total_item_ordered | numeral('0,0') }}
                            </td>
                            <td>
                                {{ item.total | numeral('0,0.00')}}
                            </td>
                            <td>
                                {{ item.total_point | numeral('0,0.00') }}
                            </td>
                            <td>
                                {{ new Date(item.created_at) | date }}
                            </td>
                            <td>
                                <span class="badge p-1"  style="color:#fff" :style="{'background-color': item.statusColor}">{{ item.statusLabel }}</span>
                            </td>
                            &lt;!&ndash;<td>&ndash;&gt;
                            &lt;!&ndash;<button type="button" class="btn btn-link btn-sm m-0 p-0" v-if="item.canCancel">Annuler</button>&ndash;&gt;
                            &lt;!&ndash;</td>&ndash;&gt;
                        </tr>
                        <tr class="border-0">
                            <td colspan="6" class="border-0">
                                <div class="row">
                                    <div class="col-12">
                                        <table style="width:100%">
                                            <tr v-for="orderItem in item.orderItems" :key="orderItem.id">
                                                <td class="border-0" scope="col">
                                                    <img :src="orderItem.product.images[0].original_url" width="40" alt=""/>
                                                </td>
                                                <td class="border-0">
                                                    {{ orderItem.product.name}} <br/>
                                                    <span v-html="orderItem.product.description.slice(0, 200)"></span>
                                                </td>
                                                <td class="border-0">{{ orderItem.qty_ordered | numeral('0,0') }} X</td>
                                                <td scope="col" class="border-0">
                                                    {{ orderItem.product.price | numeral('0,0.00') }} ({{ orderItem.product.currency.symbol }}) <br/>

                                                </td>
                                                <td scope="col" class="border-0">{{ orderItem.product.point | numeral('0,0.00') }} (Pts)</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 offset-6">
                                        <table style="width:100%">
                                            <tr>
                                                <td class="border-0 font-weight-bolder" scope="col">
                                                    Frais
                                                </td>
                                                <td class="border-0 ">
                                                    {{ totalFees(item) | numeral("0,0.00") }} {{ item.currency.symbol }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="border-0 font-weight-bolder">Total</td>
                                                <td scope="col" class="border-0">

                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
&ndash;&gt;

                </div>-->
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "orders",

  props: {
    achats: {
      required: true,
      default: () => [],
    },
    archived: {
      required: false,
      default: () => false,
    },
  },

  methods: {
    totalFees(item) {
      console.log(item.fee_amount);
      console.log(item.global_fee);
      return _.add(parseFloat(item.fee_amount), parseFloat(item.global_fee));
    },
  },
};
</script>

<style scoped></style>
