<template>
    <div>
      <br/>
      <br/>
        <div class="row mt-3 mb-3" v-if="totalCustomersPayback>0">
            <div class="col-sm-12">
                <h4 class="font-weight-bold text-center">{{ capitalizeTitle }} archivés</h4>
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col-sm-12">
              <div class="table-responsive" v-if="totalCustomersPayback>0">
                <table class="table table-hover" >
                  <thead>
                  <tr class="table-head">
                    <th scope="col">{{ capitalizeTitle }}</th>
                    <th scope="col">Date</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Actions</th>
                  </tr>
                  </thead>
                  <template v-for="(bonus,ind) in customersPaybacks">
                    <tr v-for="(item,index) in bonus.transactions" :key="index+'_'+ind">
                      <td>
                        {{ item.amount | numeral("0,0.00") }} HTG
                      </td>
                      <td>
                        {{ new Date(item.created_at) | date  }}
                      </td>
                      <td>
                        {{ item.level.name }}
                      </td>
                      <td v-if="title === 'Prime'">
                        {{ bonus.reward.name }}
                      </td>
                      <td>
                        <table>
                          <tr>
                            <td>
                              <i class="ti-save"></i>
                            </td>
                            <td>|</td>
                            <td>
                              <i class="ti-trash" style="color: red"></i>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
              <h4 class="text-center" v-else>Pas de {{ lowerTitle }} archivés</h4>
            </div>
        </div>
    </div>
</template>

<script>
  import {toLower} from "lodash/string";
  import {capitalize} from "lodash";

  export default {
    name: "customerPaybackArchived",

    props:{
      title :{
        required: true,
      },

      customersPaybacks :{
        required: true,
      }
    },
    computed: {
      test: function (){
        console.log('customersPaybacks', this.customersPaybacks)
        return 1;
      },
      totalCustomersPayback(){
        let total = 0;
        this.customersPaybacks.map(bonus=>{
          // eslint-disable-next-line no-unused-vars
          bonus.transactions.map(item=>{
            total += 1;
          })
        })
        return total
      },

      totalAmountCustomersPayback(){
        let total = 0.0;
        this.customersPaybacks.map(bonus=>{
          bonus.transactions.map(item=>{
            total += parseFloat(item.amount);
          })
        })
        console.log('total', total)
        return total
      },

      capitalizeTitle(){
        return capitalize(this.title)
      },

      lowerTitle(){
        return toLower(this.title)
      }


    }
  };
</script>

<style scoped>

</style>
