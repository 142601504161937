<template>

  <div class="row mt-3 mb-3">
    <div class="col-sm-4">
      <label><input type="radio" name="type" id="bonus" value="bonus" v-model="type"/> Bonus archivés</label><br/><br/>
      <label><input type="radio" name="type" id="prime" value="prime" v-model="type"/> Prime archivés</label>
    </div>
    <div class="col-sm-4">
      <label><input type="radio" name="type" id="commissions" value="commissions" v-model="type"/> Commissions archivés</label><br/><br/>
      <label><input type="radio" name="type" id="salaires" value="salaires" v-model="type"/> Salaires archivés</label>
    </div>
    <div class="col-sm-4">
      <label><input type="radio" name="type" id="cashback" value="cashback" v-model="type"/> Cash back archivés</label><br/><br/>
      <label><input type="radio" name="type" id="achats" value="achats" v-model="type"/> Achats archivés</label>
    </div>
    <div class="col-sm-12">
      <customerPaybackArchived :title="type" :customersPaybacks="archivedCashback" v-if="type!='achats'"/>
      <orders :achats="achats" v-if="type=='achats'" :archived="true"/>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import CustomerPaybackArchived from "./customerPaybackArchived";
import orders from "./orders";

export default {
  name: "archive",
  components: {CustomerPaybackArchived,orders},
  data() {
    return {
      type: "bonus",
    }
  },
  props: {
    wallet: {
      required: true,
      default: () => {}
    },
    allOrders: {
      required: true,
      default: () => []
    },
  },

  computed: {
    archivedCashback(){
      console.log('type', this.type)
      console.log('type', this.wallet)
      if(this.type=='bonus'){
        return _.filter(this.wallet.walletCycles, (o) => (o.reward.code === 'bonus_one' || o.reward.code === 'bonus_two'))
      }
      else if(this.type=='prime'){
        return _.filter(this.wallet.walletCycles, (o) => (o.reward.code === 'prime_direct' || o.reward.code === 'prime_indirect'))
      }
      else if(this.type=='commissions'){
        return _.filter(this.wallet.walletCycles, (o) =>  o.reward.code === 'commission')
      }
      else if(this.type=='cashback'){
        return _.filter(this.wallet.walletCycles, (o) => o.reward.code === 'cashback')
      }
      else if(this.type=='salaires'){
        return _.filter(this.wallet.walletCycles, (o) => o.reward.code === 'salary')
      }
      else{
        return []
      }
    },
    achats(){
      return _.filter(this.allOrders, (o) => !o.canCancel)
    },
  }
};
</script>

<style scoped>

</style>
