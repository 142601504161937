<template>

    <div class="row mt-3 mb-3">
        <div class="col-sm-12">
            <div class="table-responsive">
                <table class="table table-hover cart-table">
                    <tr class="table-head text-uppercase">
                        <th scope="col">Bonus</th>
                        <th scope="col">Commissions</th>
                        <th scope="col">CashBack</th>
                        <th scope="col">Primes Leaderships</th>
                        <th scope="col">Salaire</th>
                        <th scope="col">Total</th>
                    </tr>
                    <tr>
                        <td>
                            {{ parseFloat(totalIncome.bonus_one)+parseFloat(totalIncome.bonus_two) | numeral('0,0.00')}} HTG
                        </td>
                        <td>
                            {{ totalIncome.commission | numeral('0,0.00') }} HTG
                        </td>
                        <td>
                            {{ totalIncome.cashback | numeral('0,0.00') }} HTG
                        </td>
                        <td>
                            {{ parseFloat(totalIncome.prime_direct)+parseFloat(totalIncome.prime_indirect) | numeral('0,0.00')}} HTG
                        </td>
                        <td>
                            {{ totalIncome.salary | numeral('0,0.00') }} HTG
                        </td>
                        <td>
                            <strong>{{ parseFloat(totalIncome.bonus_one)+parseFloat(totalIncome.bonus_two) + parseFloat(totalIncome.commission) + parseFloat(totalIncome.cashback) + parseFloat(totalIncome.prime_direct)+parseFloat(totalIncome.prime_indirect) + parseFloat(totalIncome.salary) | numeral('0,0.00')}} HTG</strong>
                        </td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
    //import _ from 'lodash';

  export default {
    name: "totals",

    props: {
      totalIncome: {
        required: true,
        default: () => {}
      }
    },

    computed: {
      /*bonus(){
        return _.reduce(_.filter(this.wallets, (o) => o.reward.code === 'bonus_one' || o.reward.code === 'bonus_two'), function(sum, n){
          return sum + parseFloat(n.balance)
        }, 0)
      },

      commission(){
        return _.reduce(_.filter(this.wallets, (o) => o.reward.code === 'commission'), function(sum, n){
          return sum + parseFloat(n.balance)
        }, 0)
      },

      prime(){
        return _.reduce(_.filter(this.wallets, (o) => o.reward.code === 'prime_direct' || o.reward.code === 'prime_indirect'), function(sum, n){
          return sum + parseFloat(n.balance)
        }, 0)
      },

      cashback(){
        return _.reduce(_.filter(this.wallets, (o) => o.reward.code === 'cashback'), function(sum, n){
          return sum + parseFloat(n.balance)
        }, 0)
      },

      salary(){
        return _.reduce(_.filter(this.wallets, (o) => o.reward.code === 'salary'), function(sum, n){
          return sum + parseFloat(n.balance)
        }, 0)
      }*/
    }
  };
</script>

<style scoped>

</style>
